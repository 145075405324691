<template>
  <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
    <span
      slot="id"
      slot-scope="text"
    >{{text}}</span>
    <span slot="name" slot-scope="record"> {{ record.first_name }}</span>
    <span slot="last_name" slot-scope="record"> {{ record.last_name }}</span>
    <span slot="alias" slot-scope="record">{{ record.status }}</span>
    <span slot="position" slot-scope="record" class="logo"><img :src="record.icon" alt="logo"></span>
    <span slot="action" slot-scope="record">
            <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$emit('edit', record.id)">
              <i class="fe fe-edit mr-2" />
              Изменить
            </a>
              <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="$emit('overview', record.id)">
                <small>
                  <i class="fe fe-more-horizontal mr-2" />
                </small>
                Просмотр
              </a>
          </span>
  </a-table>
</template>

<script>

export default {
  name: 'CheckPageTable',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Имя',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Фамилия',
          scopedSlots: { customRender: 'last_name' },
        },
        {
          title: 'Телефон',
          dataIndex: 'phone',
        },
        {
          title: 'Должность',
          scopedSlots: { customRender: 'alias' },
        },
        {
          title: 'Фото',
          scopedSlots: { customRender: 'position' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.logo {
  width: auto;
  height: 24px;
  display: block;
  img {
    height: 100%;
  }
}
</style>
